import React from 'react'
import './Plans.css'
import {plansData} from '../../data/plansData'
import whiteTick from "../../assets/whiteTick.png"
import RightArrow from '../../assets/rightArrow.png'
const Plans = () => {
  return (
      <div className="plans" id="plans">
        <div className="blur plans-blur-1"></div>
        <div className="blur plans-blur-2"></div>
        <div className="plans-header">
            <span className='stroke-text'>Ready tp start</span>
            <span>your journey</span>
            <span className='stroke-text'>now withus</span>
        </div>
        <div className="plan-categories">
          {plansData.map((plan, i)=>(
            <div className="plan" key={i}>
              {plan.icon}
              <span>
                {plan.name}
              </span>
              <span>
                $ {plan.price}
              </span>
              <div className='features'>
                {plan.features.map((feature, i)=>(
                  <div className="feature">
                    <img src={whiteTick} alt="" className='imgf'/>
                    <span key={i}> {feature}</span>
                  </div>
                ))}
              </div>
              <div className="seemore"><span>See more benefits</span><img src={RightArrow} alt="" /></div>
              <button className="btn">Join Now</button>
            </div>
          ))}
        </div>
      </div>
    )
}

export default Plans